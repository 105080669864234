<template>
  <div class="">
    <v-navigation-drawer app class="ma-0 pa-0" v-model="mostrar">
       <opciones tipo="reporte" />
    </v-navigation-drawer>
    <v-app-bar app class="mb-0 pb-0">
      <v-app-bar-nav-icon @click="mostrar = !mostrar"></v-app-bar-nav-icon>
      <h3 color="white--text">REPORTE</h3>
    </v-app-bar>
    <v-container elevation="10" class="px-10">
      <v-card>
        <!-- CONTENIDO -->
        <div class="">
          <h3 class="titulo">
            <center>ESTATUS DE COTIZACIONES POR RANGO DE DÍAS</center>
          </h3>

          <tablaReporte />
        </div>
        <!-- <div class="mt-5">
          <h3 class="titulo">
            <center>
              ESTATUS DE COTIZACIONES POR RANGO DE DÍAS DESDE LA ÚLTIMA LLAMADA
            </center>
          </h3>

          <tablaReporteUltimaLllamada />
        </div> -->
        <!-- CONTENIDO -->
      </v-card>
    </v-container>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import opciones from "@/components/comun/opciones.vue";

export default {
  name: "reporteCotizacionView",
  data() {
    return {
      mostrar: true,

      tablaFlag: {
        tabla: true,
        rangoDias: false,
      },
      tab: null,
      items: ["Tabla", "STATUS COT X RANGO DIAS", "videos", "images", "news"],
      text:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    };
  },
  components: {
    tablaReporte: () =>
      import("@/components/reporte/tablaReporteComponents.vue"),
    // tablaReporteUltimaLllamada: () =>
    //   import("@/components/reporte/tablaReporteUltimaLlamadaComponents.vue"),
    opciones,
  },
  computed: {
    ...mapState(["titulo"]),
  },
  mounted() {
    let opciones = [
      {
        id: 1,
        nombre: "Reporte",
        class: "active",
        url: "reporte",
      },
       {
        id: 2,
        nombre: "Reporte Abiertas",
        url: "reporte_abierta",
      },
    ];

    this.opcioneMenuLista(opciones);
  },
  methods: {
    ...mapMutations(["opcioneMenuLista"]),
    cambiarStep(i) {
      switch (i) {
        case 1:
          this.tablaFlag.tabla = true;
          this.tablaFlag.rangoDias = false;
          break;
        case 2:
          this.tablaFlag.tabla = false;
          this.tablaFlag.rangoDias = true;
          break;

        default:
          break;
      }
    },
  },
};
</script>

<style scope>
html {
  scroll-behavior: smooth;
}

.titulo {
  color: white !important;
  padding: 0.7rem;
  background: rgb(32, 55, 100);
}
/* .v-tab {
  transform: scale(1.1);
} */
</style>
